import * as Sentry from '@sentry/svelte';
import type { HandleClientError } from '@sveltejs/kit';

export const handleError: HandleClientError = ({ error, event }) => {
	Sentry.captureException(error, (scope) => scope.setExtra('event', event));

	return {
		message: `Whoops!${JSON.stringify(error)}`,
		code: error?.code ?? 'UNKNOWN'
	};
};
